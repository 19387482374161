<template lang="">
  <div>
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-4 md:grid-cols-2">
          <TitlePlus :title="$t('components.marketPlaceManagement.orders.details.marketplace.steps.revenues.headline')"  :hide-plus="true" />
          <div class="md:flex md:justify-end">
            <DateRangePicker
              @apply-date="onApplyFilterDateRange"
              @cancel-date="onApplyFilterDateRange"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-2 my-1 md:items-center md:flex-row px-5">
        <SummaryCard
          :title="$t('components.marketPlaceManagement.orders.details.marketplace.steps.revenues.summary.total')"
          :value="indexMetaData.count.total"
          variant="gray"
        />
      </div>

      <FSTable
        :fst-id="`marketplaceRevenueIndex`"
        :searchEnabled="false"
        :headers="tableHeaders"
        :endpoint="getEndpoint"
        :qso="getEndpointOptions"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #default="{ data }">
          <FSTableRow
            v-for="(item, itemIndex) in data"
            :key="itemIndex"
            text-fallback-always
          >
            <FSTableRowItem :text="item.id" :truncate="-5" />
            <FSTableRowItem
              :text="`${item.buyer.first_name} ${item.buyer.last_name}`"
            />
            <FSTableRowItem
              :text="item.fleet.fleet.name"
              :to="{
                name: 'ViewFleetProfile',
                params: { id: item.fleet.fleet.id, redirectPath: path },
              }"
            />
            <FSTableRowItem :text="item.issued_at" :date="true" />
            <FSTableRowItem :text="item.paid_at" :date="true" />
            <FSTableRowItem :text="getMonthYear(item.billing_period)" />
            <FSTableRowItem :text="item.vehicle_quantity || '--'" />
            <FSTableRowItem
              :text="`${item.fleet.fleet.country.currency_symbol} ${item.tax}`"
            />
            <FSTableRowItem
              :text="
                `${item.fleet.fleet.country.currency_symbol} ${item.total_amount}`
              "
            />
            <FSTableRowItem :text="item.description || '--'" />
            <FSTableRowItem>
              <XStatus
                :text="item.status"
                :variant="getItemVariant(item.status)"
                profile="payment"
              />
            </FSTableRowItem>
          </FSTableRow>
        </template>
      </FSTable>
    </content-section>
  </div>
</template>
<script>
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import DateRangePicker from '@/components/picker/date-range/DateRangePicker'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  onApplyFilterDateRange,
} from '@/components/fs-table'
import { MarketplaceConfig } from '@/config/MarketplaceConfig'
import { getFormattedDateTime } from '@/utils/datetime'
import XStatus from '@/components/badge/XStatus'
export default {
  name: 'ViewMarketplaceRevenues',
  components: {
    SummaryCard: () => import('@/components/cards/SummaryCard'),
    DateRangePicker,
    ContentSection,
    TitlePlus,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    XStatus,
  },
  data() {
    return {
      indexMetaData: {
        count: {
          total: 0,
        },
      },
      path: '',
      tableHeaders: [
        { text: this.$t('components.marketPlaceManagement.orders.details.marketplace.steps.revenues.table.columns.invoiceId'), width: '10%', sort: 'id' },
        { text: this.$t('components.marketPlaceManagement.orders.details.marketplace.steps.revenues.table.columns.invoiceFor'), width: '10%', sort: 'null' },
        { text: this.$t('components.marketPlaceManagement.orders.details.marketplace.steps.revenues.table.columns.fleet'), width: '10%', sort: 'null' },
        { text: this.$t('components.marketPlaceManagement.orders.details.marketplace.steps.revenues.table.columns.issuedAt'), width: '12%', sort: 'null' },
        { text: this.$t('components.marketPlaceManagement.orders.details.marketplace.steps.revenues.table.columns.paidAt'), width: '12%', sort: 'null' },
        {  text: this.$t('components.marketPlaceManagement.orders.details.marketplace.steps.revenues.table.columns.billingPeriod'),width: '10%', sort: 'null' },
        {  text: this.$t('components.marketPlaceManagement.orders.details.marketplace.steps.revenues.table.columns.vehicleQuantity'), width: '10%', sort: 'null' },
        {  text: this.$t('components.marketPlaceManagement.orders.details.marketplace.steps.revenues.table.columns.tax'), width: '5%', sort: 'null' },
        { text: this.$t('components.marketPlaceManagement.orders.details.marketplace.steps.revenues.table.columns.amount'), width: '7%', sort: 'null' },
        { text: this.$t('components.marketPlaceManagement.orders.details.marketplace.steps.revenues.table.columns.description'), width: '13%', sort: 'null' },
        { text: this.$t('components.marketPlaceManagement.orders.details.marketplace.steps.revenues.table.columns.status'), width: '10%', sort: 'null' },
      ],
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    getEndpoint() {
      return MarketplaceConfig.api.monthlyRevenueIndex(this.id)
    },

    getEndpointOptions() {
      return {
        prepend: '&',
        append: '',
      }
    },
  },
  async created() {
    this.path = this.$route.path
  },
  methods: {
    onApplyFilterDateRange,
    getFormattedDateTime,
    getItemVariant(data) {
      if (data === 'paid') return 'green'
      if (data === 'unpaid') return 'gray'
      return 'red'
    },
    getMonthYear(date) {
      const splittedDate = date.split('/')
      const zuluDate = `${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}T00:00:00`
      return this.getFormattedDateTime(zuluDate, 'MMM, YYYY')
    },
  },
}
</script>
<style lang=""></style>
